import {CAvatar, CBadge, CButton} from "@coreui/react";
import {getProfilePictureUri} from "../../../../Utils/UriUtils";

export const columns = (venues, handleEditButtonClick) => [
    {
        name: '',
        selector: row => row.managerUser?.profile_image,
        cell: row => (
            <div class="py-2">
                <CAvatar size="md" shape="rounded-circle" src={getProfilePictureUri(row.managerUser?.profile_image, "../../../../assets")}/>
            </div>
        ),
        width: "75px",
        wrap: true,
        reorder: true,
    },
    {
        name: 'Name',
        selector: row => row.managerUser?.name,
        width: "200px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Phone',
        selector: row => row.managerUser?.phone || "N/A",
        width: "150px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Email',
        selector: row => row.managerUser?.email || "N/A",
        width: "250px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Venues',
        selector: row => row.venue_ids,
        cell: row => {
            return <span class="py-2 display-line-break">{
                row.venue_ids.map(venueId => {
                    return venues.find((venue) => venue.id === venueId)?.name;
                }).filter(venueName => venueName).join('\n')
            }</span>;
        },
        width: "200px",
        wrap: true,
        reorder: true,
    },
    {
        name: 'Edit Venues',
        selector: row => row.editVenueAccess,
        cell: row => {
            switch (row.editVenueAccess) {
                case 0:
                    return <CBadge color="danger">No</CBadge>;
                case 1:
                    return <CBadge color="success">Yes</CBadge>;
            }
        },
        width: "125px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Edit Reservation Reports',
        selector: row => row.editReservationReportsAccess,
        cell: row => {
            switch (row.editReservationReportsAccess) {
                case 0:
                    return <CBadge color="danger">No</CBadge>;
                case 1:
                    return <CBadge color="success">Yes</CBadge>;
            }
        },
        width: "200px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Edit Customer Restrictions',
        selector: row => row.editCustomerAccess,
        cell: row => {
            switch (row.editCustomerAccess) {
                case 0:
                    return <CBadge color="danger">No</CBadge>;
                case 1:
                    return <CBadge color="success">Yes</CBadge>;
            }
        },
        width: "200px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Edit Events',
        selector: row => row.editEventAccess,
        cell: row => {
            switch (row.editEventAccess) {
                case 0:
                    return <CBadge color="danger">No</CBadge>;
                case 1:
                    return <CBadge color="success">Yes</CBadge>;
            }
        },
        width: "125px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Accept Bookings',
        selector: row => row.acceptBookingAccess,
        cell: row => {
            switch (row.acceptBookingAccess) {
                case 0:
                    return <CBadge color="danger">No</CBadge>;
                case 1:
                    return <CBadge color="success">Yes</CBadge>;
            }
        },
        width: "150px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Access DoubleSpot AI',
        selector: row => row.aiAccess,
        cell: row => {
            switch (row.aiAccess) {
                case 0:
                    return <CBadge color="danger">No</CBadge>;
                case 1:
                    return <CBadge color="success">Yes</CBadge>;
            }
        },
        width: "175px",
        sortable: true,
        reorder: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" onClick={() => handleEditButtonClick(row.id)}>
                    Edit
                </CButton>
            </div>
        ),
        minWidth: "125px",
        right: true,
    },
];

export const filterCategories = [
    {
        name: 'Name',
        category: 'name',
        type: 'exact',
    },
    {
        name: 'Phone',
        category: 'phone',
        type: 'exact',
    },
    {
        name: 'Email',
        category: 'email',
        type: 'exact',
    },
    {
        name: 'Venues',
        category: 'venues',
        type: 'multiple',
    },
    {
        name: 'Edit Venues',
        category: 'editVenueAccess',
        type: 'multiple',
    },
    {
        name: 'Edit Events',
        category: 'editEventAccess',
        type: 'multiple',
    },
    {
        name: 'Accept Bookings',
        category: 'acceptBookingAccess',
        type: 'multiple',
    },
];

export const applyFilters = (managers, filters) => {
    return managers.filter(manager => {
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(
                manager.name.toLowerCase().includes(searchTerm) ||
                manager.email.toLowerCase().includes(searchTerm)
            )) {
                return false;
            }
        }

        if (filters.name) {
            const searchTerm = filters.name.toLowerCase();
            if (!manager.name.toLowerCase().includes(searchTerm)) return false;
        }

        if (filters.phone) {
            if (!manager.phone.includes(filters.phone)) return false;
        }

        if (filters.email) {
            const searchTerm = filters.email.toLowerCase();
            if (!manager.email.toLowerCase().includes(searchTerm)) return false;
        }

        if (filters.venues?.length) {
            let includesVenue = false;
            filters.venues.map(venue => venue.value).map((venueId) => {
                if (manager.venue_ids.includes(venueId)) {
                    includesVenue = true;
                }
            })
            if (!includesVenue) return false;
        }

        if (filters.editVenueAccess?.length) {
            if (!filters.editVenueAccess.map(accessLevels => accessLevels.value).includes(manager.editVenueAccess)) return false;
        }
        if (filters.editReservationReportsAccess?.length) {
            if (!filters.editReservationReportsAccess.map(accessLevels => accessLevels.value).includes(manager.editReservationReportsAccess)) return false;
        }
        if (filters.editCustomerAccess?.length) {
            if (!filters.editCustomerAccess.map(accessLevels => accessLevels.value).includes(manager.editCustomerAccess)) return false;
        }
        if (filters.editEventAccess?.length) {
            if (!filters.editEventAccess.map(accessLevels => accessLevels.value).includes(manager.editEventAccess)) return false;
        }
        if (filters.acceptBookingAccess?.length) {
            if (!filters.acceptBookingAccess.map(accessLevels => accessLevels.value).includes(manager.acceptBookingAccess)) return false;
        }
        if (filters.aiAccess?.length) {
            if (!filters.aiAccess.map(accessLevels => accessLevels.value).includes(manager.aiAccess)) return false;
        }

        return true;
    });
};
