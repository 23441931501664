import React, {useEffect, useState} from "react";
import {
    CCard,
    CCardBody,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
import FilterModal from "./FilterModal";
import {columns, filterCategories, applyFilters} from "./FilterUtils";
import {getManagers, getProviderVenueListing, setAlert} from "../../../../redux/action/action";
import EditModal from "./EditModal";
import {useNavigate} from "react-router-dom";
import Filters from "../../../components/Filters";
import {users} from "constants";

const Team = () => {
    document.title = "Team | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Organize your team members and their user access and privileges with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetail = useSelector(state => state.Apis.userDetail);
    const venueListing = useSelector(state => state.Apis.providerVenuesListing);
    const managerListing = useSelector(state => state.Apis.managers);
    const updatedManager = useSelector(state => state.Apis.updatedManager);

    const [venues, setVenues] = useState([]);
    const [managers, setManagers] = useState([]);
    const [filters, setFilters] = useState({});
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editFormData, setEditFormData] = useState({});

    const updateFilters = (event) => {
        setFilters({...filters, [event.target.name]: event.target.value});
    }

    const updateFiltersMultiselect = (selectedOptions, actionMeta) => {
        setFilters({...filters, [actionMeta.name]: selectedOptions});
    }

    const handleEditButtonClick = (id) => {
        const manager = managerListing.find(manager => manager.id === id);
        setEditFormData({
            id: manager.id,
            name: manager.managerUser?.name,
            venues: manager.venue_ids
                .map(venueId => ({
                    label: venues.find(venue => venue.id === venueId)?.name,
                    value: venueId,
                }))
                .filter(venue => venue.label),
            editVenueAccess: manager.editVenueAccess,
            editReservationReportsAccess: manager.editReservationReportsAccess,
            editCustomerAccess: manager.editCustomerAccess,
            editEventAccess: manager.editEventAccess,
            acceptBookingAccess: manager.acceptBookingAccess,
            aiAccess: manager.aiAccess,
        });
        setEditModalVisible(true);
    }

    useEffect(() => {
        dispatch(getProviderVenueListing());
    }, []);

    useEffect(() => {
        setVenues(venueListing);
    }, [venueListing]);

    useEffect(() => {
        setManagers(applyFilters(managerListing, filters));
    }, [managerListing, filters]);

    useEffect(() => {
        let count = 0;
        filterCategories.map(filter => {
            if (filter.type === 'exact') {
                if (filters[filter.category]) {
                    count += 1;
                }
            } else if (filter.type === 'multiple') {
                if (filters[filter.category]?.length > 0) {
                    count += 1;
                }
            } else if (filter.type === 'range') {
                const [minCategory, maxCategory] = filter.category;
                if (filters[minCategory] || filters[maxCategory]) {
                    count += 1;
                }
            }
        })
        setFilterCount(count);
    }, [filters]);

    useEffect(() => {
        if (updatedManager) {
            setEditModalVisible(false);
        }
        dispatch(getManagers());
    }, [updatedManager]);

    useEffect(() => {
        if (userDetail.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editTeamAccess) {
                dispatch(setAlert('You do not have the permissions to view the team.','error'));
                navigate("/business/reservations/calendar");
            }
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Team</h2>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <Filters
                            filters={filters}
                            setFilters={setFilters}
                            filterCount={filterCount}
                            updateFilters={updateFilters}
                            filterCategories={filterCategories}
                            setFilterModalVisible={setFilterModalVisible}
                        />
                    </div>
                    <DataTable
                        columns={columns(venues, handleEditButtonClick)}
                        data={managers}
                        pagination
                    />
                </CCardBody>
            </CCard>
            <FilterModal
                visible={filterModalVisible}
                onClose={() => setFilterModalVisible(false)}
                updateFilters={updateFilters}
                updateFiltersMultiselect={updateFiltersMultiselect}
                filters={filters}
                venues={venues}
                />
            <EditModal
                visible={editModalVisible}
                onClose={() => setEditModalVisible(false)}
                editFormData={editFormData}
                setEditFormData={setEditFormData}
                dispatch={dispatch}
                venues={venues}
                managerName={editFormData.name}
                />
        </div>
    );
}

export default Team;
