import {CButton} from "@coreui/react";

export const columns = [
    {
        name: 'Name',
        selector: row => row?.name,
        width: "250px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Address',
        selector: row => row?.location,
        width: "350px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Capacity',
        selector: row => row?.max_attendees || "N/A",
        width: "125px",
        sortable: true,
    },
    {
        name: 'Hourly Price',
        selector: row => row?.process_payment ? `$${row?.hourly_price}` : "N/A",
        width: "125px",
        wrap: true,
        sortable: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" href={`/business/venues/${row?.id}`} target="_blank">Edit</CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    },
];
