const moment = require("moment");
const {getOrdinalSuffix} = require("./StringUtils");
const bookvenues = require("constants").bookvenues;

const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

/**
 *
 * @param date {string} The date to be formatted in 'yyyy-mm-dd' format.
 * @param time {string|null} The time to be formatted in 'hh:mm:ss:' format. Use null if the return value desired does not contain time information.
 * @param longDate {boolean} Whether the date should be formatted as "Friday, June 1th, 2023 or 6/1/2023".
 * @returns {string} A formatted date time string in 12-hour format with weekday included, e.g. 'Thursday, March 7, 2024 at 00:00 AM', or an empty string if parsing of original date and time are invalid.
 */
const formatDate = (date, time = null, longDate = true) => {
    try {
        if (!date) return '';
        return Intl.DateTimeFormat('en-US', {
            weekday: longDate ? 'long' : undefined,
            year: 'numeric',
            month: longDate ? 'long' : 'numeric',
            day: 'numeric',
            hour: time ? '2-digit' : undefined,
            minute: time ? '2-digit' : undefined,
            timeZone: 'UTC',
        }).format(new Date(`${date}T${time || "00:00:00"}Z`));
    } catch {
        return '';
    }
}

/**
 *
 * @param date {Date} The date object to be formatted into a time string.
 * @param ampm {boolean} Whether to show the time string in 12-hour system.
 * @returns {string} The formatted time string. In 12-hour system, the result would look like '01:57 PM', and in 24-hour system, the result would look like '13:57'.
 */
const getTimeString = (date, ampm = false) => {
    try {
        if (!date) return '';
        return Intl.DateTimeFormat('en', {
            hourCycle: ampm ? 'h12' : 'h23',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date);
    } catch {
        return '';
    }
}

/**
 *
 * @param date {Date} The date to be quantized.
 * @param interval {number} The quantization unit in minutes.
 * @returns {Date} The quantized date with the interval as the basic unit, and is guaranteed to be the latest date that is earlier or equal to the original.
 */
const dateFloor = (date, interval = 30) => {
    let totalMinutes = date.getHours() * 60 + date.getMinutes();
    totalMinutes = Math.floor(totalMinutes / interval) * interval;
    date.setHours(Math.floor(totalMinutes / 60), totalMinutes % 60);
    return date;
}

/**
 *
 * @param date {Date} The date to be quantized.
 * @param interval {number} The quantization unit in minutes.
 * @returns {Date} The quantized date with the interval as the basic unit, and is guaranteed to be the earliest date that is later or equal to the original.
 */
const dateCeil = (date, interval = 30) => {
    let totalMinutes = date.getHours() * 60 + date.getMinutes();
    totalMinutes = Math.ceil(totalMinutes / interval) * interval;
    date.setHours(Math.floor(totalMinutes / 60), totalMinutes % 60);
    return date;
}

/**
 *
 * @param date {Date} The date to be added with a certain number of hours.
 * @param hours {number} The number of hours to shift the date by.
 * @returns {Date} The date after being shifted the given hours to the future.
 */
const timeAdd = (date, hours) => {
    return moment(date).add(hours, 'h').toDate();
}

/**
 *
 * @param date1 {Date} One of the dates to be compared.
 * @param date2 {Date} One of the dates to be compared.
 * @returns {Date} The earlier date of the two.
 */
const dateMin = (date1, date2) => {
    return new Date(Math.min(date1, date2));
}

/**
 *
 * @param date1 {Date} One of the dates to be compared.
 * @param date2 {Date} One of the dates to be compared.
 * @returns {Date} The later date of the two.
 */
const dateMax = (date1, date2) => {
    return new Date(Math.max(date1, date2));
}

/**
 * This function only works in cases where startTime and endTime are within the same day.
 * @param startTime {Date} The earliest time of the returned list of times.
 * @param endTime {Date} The latest time of the returned list of times.
 * @param interval {number} The interval between the times in minutes.
 * @returns {Date[]} The list of times from startTime to endTime with the designated interval and inclusive boundaries.
 */
const timeRange = (startTime, endTime, interval = 30) => {
    const result = [];
    startTime = dateCeil(startTime, interval);
    endTime = dateFloor(endTime, interval);
    for (let minutes = startTime.getHours() * 60 + startTime.getMinutes(); minutes <= endTime.getHours() * 60 + endTime.getMinutes(); minutes += interval) {
        const newDate = new Date(startTime);
        newDate.setHours(Math.floor(minutes / 60), minutes % 60);
        result.push(newDate);
    }

    return result;
}

/**
 *
 * @param venueStartTime {string} The opening time of the venue in the format of 'hh:mm:ss'.
 * @param venueEndTime {string} The closing time of the venue in the format of 'hh:mm:ss'.
 * @param date {string} The date to get availability on in the format of 'yyyy-mm-dd'.
 * @param existingSlots {{date: string, end_date: string, start_time: string, end_time: string, status: number}[]} A list of taken time slots. 'date' and 'end_date' should be in the format of 'yyyy-mm-dd' and 'start_time' and 'end_time' should be in the format of 'hh:mm:ss'.
 * @param hoursBetweenEvents {number} The minimum hours required between events.
 * @returns {Map<string, boolean>} A map of date strings and their availability. For example, between an event that ends at 9AM and another that starts at 10AM, both 9AM and 10AM would be marked as available.
 */
const getAvailabilityMap = (venueStartTime, venueEndTime, date, existingSlots, hoursBetweenEvents = 0) => {
    venueStartTime = venueStartTime ? venueStartTime : '09:00:00';
    venueEndTime = venueEndTime ? venueEndTime : '17:00:00';
    const availabilityMap = new Map(timeRange(new Date(`${date} ${venueStartTime}`), new Date(`${date} ${venueEndTime}`)).map(time => [time.toString(), true]));

    existingSlots.forEach((slot) => {
        if (new Date(slot.date) < new Date(date) || new Date(slot.end_date) > new Date(date)) {
            availabilityMap.forEach((_, time) => {
                availabilityMap.set(time, false);
            })
            return availabilityMap;
        }
        // Using dateMax and dateMin limits the time range to a single day and prevent bugs.
        // Status 3 indicates blocked times added by the business, and do not need to account for extra buffer time.
        timeRange(
            dateMax(
                timeAdd(new Date(`${slot.date} ${slot.start_time}`), slot.status === bookvenues.STATUS_INTERNAL ? 0 : -hoursBetweenEvents),
                new Date(`${slot.date} 00:00:00`),
            ),
            dateMin(
                timeAdd(new Date(`${slot.end_date} ${slot.end_time}`), slot.status === bookvenues.STATUS_INTERNAL ? 0 : hoursBetweenEvents),
                new Date(`${slot.end_date} 23:59:59`),
            )
        ).slice(1, -1).forEach((time) => {
            if (availabilityMap.has(time.toString())) {
                availabilityMap.set(time.toString(), false);
            }
        });
    });

    return availabilityMap;
}

/**
 *
 * @param venueStartTime {string} The opening time of the venue in the format of 'hh:mm:ss'.
 * @param venueEndTime {string} The closing time of the venue in the format of 'hh:mm:ss'.
 * @param date {string} The date to get availability on in the format of 'yyyy-mm-dd'.
 * @param existingSlots {{date: string, end_date: string, start_time: string, end_time: string, status: number}[]} A list of taken time slots. 'date' and 'end_date' should be in the format of 'yyyy-mm-dd' and 'start_time' and 'end_time' should be in the format of 'hh:mm:ss'.
 * @param minHours {number} The minimum number of hours a booking should have.
 * @param hoursBetweenEvents {number} The minimum hours required between events.
 * @returns {{endTimes: Date[], startTimes: Date[]}} The available start and end times for bookings.
 */
const getAvailableSlots = (venueStartTime, venueEndTime, date, existingSlots, minHours, hoursBetweenEvents = 0) => {
    const availabilityPairs = Array.from(getAvailabilityMap(venueStartTime, venueEndTime, date, existingSlots, hoursBetweenEvents));
    const minSlots = minHours * 2;
    let streakStart = -1;
    let streak = 0;
    const result = {
        startTimes: [],
        endTimes: []
    };

    while (true) {
        const newIndex = streakStart + streak + 1;
        if (newIndex >= availabilityPairs.length) {
            if (newIndex - minSlots > 0) {
                result.startTimes = result.startTimes.concat(availabilityPairs.slice(streakStart, newIndex - minSlots));
            }
            result.endTimes = result.endTimes.concat(availabilityPairs.slice(streakStart + minSlots, newIndex));
            break;
        }
        if (availabilityPairs[newIndex][1]) {
            if (streak === 0) streakStart = newIndex;
            streak += 1;
        } else {
            if (streak > 0) {
                if (newIndex - minSlots > 0) {
                    result.startTimes = result.startTimes.concat(availabilityPairs.slice(streakStart, newIndex - minSlots));
                }
                result.endTimes = result.endTimes.concat(availabilityPairs.slice(streakStart + minSlots, newIndex));
                streak = 0;
            }
            streakStart = newIndex;
        }
    }
    result.startTimes = result.startTimes.map(pair => new Date(pair[0]));
    result.endTimes = result.endTimes.map(pair => new Date(pair[0]));

    return result;
}

/**
 *
 * @param venueStartTime {string} The opening time of the venue in the format of 'hh:mm:ss'.
 * @param venueEndTime {string} The closing time of the venue in the format of 'hh:mm:ss'.
 * @param date {string} The date to get availability on in the format of 'yyyy-mm-dd'.
 * @param existingSlots {{date: string, end_date: string, start_time: string, end_time: string, status: number}[]} A list of taken time slots. 'date' and 'end_date' should be in the format of 'yyyy-mm-dd' and 'start_time' and 'end_time' should be in the format of 'hh:mm:ss'.
 * @param eventEndTime {string} The ending time selected for a booking in the format of 'hh:mm'.
 * @param minHours {number} The minimum number of hours a booking should have.
 * @param maxHours {number|null} The maximum number of hours a booking should have.
 * @param hoursBetweenEvents {number} The minimum hours required between events.
 * @returns {Date[]} The list of available starting times based on the given ending time.
 */
const getAvailableStartTimes = (venueStartTime, venueEndTime, date, existingSlots, eventEndTime, minHours, maxHours = null, hoursBetweenEvents = 0) => {
    if (!eventEndTime) {
        return getAvailableSlots(venueStartTime, venueEndTime, date, existingSlots, minHours, hoursBetweenEvents).startTimes;
    }

    const availabilityPairs = Array.from(getAvailabilityMap(venueStartTime, venueEndTime, date, existingSlots, hoursBetweenEvents)).reverse();
    const minSlots = minHours * 2;
    const maxSlots = maxHours * 2;
    let streakStart;
    let streak = 0;
    let streakAlive = true;

    availabilityPairs.forEach((pair, index) => {
        if (getTimeString(new Date(pair[0]), false) === eventEndTime) {
            if (pair[1]) {
                streakStart = index;
                streak = 1;
            } else {
                return [];
            }
        } else if (streak > 0) {
            if (pair[1] && streakAlive) {
                streak += 1;
            } else {
                streakAlive = false;
            }
        }
    });
    streak = maxHours ? Math.min(streak, maxSlots + 1) : streak;

    return streakStart === undefined ? [] : availabilityPairs.slice(streakStart + minSlots, streakStart + streak).map(pair => new Date(pair[0])).reverse();
}

/**
 *
 * @param venueStartTime {string} The opening time of the venue in the format of 'hh:mm:ss'.
 * @param venueEndTime {string} The closing time of the venue in the format of 'hh:mm:ss'.
 * @param date {string} The date to get availability on in the format of 'yyyy-mm-dd'.
 * @param existingSlots {{date: string, end_date: string, start_time: string, end_time: string, status: number}[]} A list of taken time slots. 'date' and 'end_date' should be in the format of 'yyyy-mm-dd' and 'start_time' and 'end_time' should be in the format of 'hh:mm:ss'.
 * @param eventStartTime {string} The starting time selected for a booking in the format of 'hh:mm'.
 * @param minHours {number} The minimum number of hours a booking should have.
 * @param maxHours {number|null} The maximum number of hours a booking should have.
 * @param hoursBetweenEvents {number} The minimum hours required between events.
 * @returns {Date[]} The list of available ending times based on the given ending time.
 */
const getAvailableEndTimes = (venueStartTime, venueEndTime, date, existingSlots, eventStartTime, minHours, maxHours = null, hoursBetweenEvents = 0) => {
    if (!eventStartTime) {
        return getAvailableSlots(venueStartTime, venueEndTime, date, existingSlots, minHours, hoursBetweenEvents).endTimes;
    }

    const availabilityPairs = Array.from(getAvailabilityMap(venueStartTime, venueEndTime, date, existingSlots, hoursBetweenEvents));
    const minSlots = minHours * 2;
    const maxSlots = maxHours * 2;
    let streakStart;
    let streak = 0;
    let streakAlive = true;

    availabilityPairs.forEach((pair, index) => {
        if (getTimeString(new Date(pair[0]), false) === eventStartTime) {
            if (pair[1]) {
                streakStart = index;
                streak = 1;
            } else {
                return [];
            }
        } else if (streak > 0) {
            if (pair[1] && streakAlive) {
                streak += 1;
            } else {
                streakAlive = false;
            }
        }
    });
    streak = maxHours ? Math.min(streak, maxSlots + 1) : streak;

    return streakStart === undefined ? [] : availabilityPairs.slice(streakStart + minSlots, streakStart + streak).map(pair => new Date(pair[0]));
}

/**
 *
 * @param eventData {{date: string, end_date: string, start_time: string, end_time: string}} The event to check for time conflicts with bookings.
 * @param bookings {{date: string, end_date: string, start_time: string, end_time: string}[]} The list of bookings to check for time conflicts with eventData.
 * @returns {boolean} Whether there is a time overlap between eventData and any of the entries in bookings.
 */
const hasConflict = (eventData, bookings) => {
    const eventStart = new Date(`${eventData.date} ${eventData.start_time}`);
    const eventEnd = new Date(`${eventData.end_date} ${eventData.end_time}`);
    const filtered = bookings
        .filter(booking => '' + booking.venueId === eventData.venueId)
        .filter(booking => {
            const bookingStart = new Date(`${booking.date} ${booking.start_time}`);
            const bookingEnd = new Date(`${booking.end_date} ${booking.end_time}`);
            return bookingStart < eventEnd && bookingEnd > eventStart;
        });
    return filtered.length > 0;
}

/**
 *
 * @param date {Date} The date to be added with a certain number of days.
 * @param day {number} The number of days to shift the date by.
 * @returns {Date} The date after being shifted the given days to the future.
 */
const dateAdd = (date, day) => {
    return moment(date).add(day, 'd').toDate();
}

/**
 *
 * @param startDate {Date} The earliest date of the returned list of dates.
 * @param endDate {Date} The latest date of the returned list of dates.
 * @param interval {number} The interval between the dates in days.
 * @returns {Date[]} The list of dates from startDate to endDate with the designated interval and inclusive boundaries.
 */
const dateRange = (startDate, endDate, interval = 1) => {
    const result = [];
    for (let date = startDate; date <= endDate; date = dateAdd(date, interval)) {
        result.push(date);
    }

    return result;
}

/**
 *
 * @param date {Date} The earliest date of the returned list of dates.
 * @param length {number} The length of the returned list of dates.
 * @param interval {number} The interval between the dates in days.
 * @returns {Date[]} The list of dates from startDate with the designated length and interval.
 */
const dateRangeByLength = (date, length, interval = 1) => {
    const result = [];
    for (let i = 0; i < length; ++i) {
        result.push(dateAdd(date, i * interval));
    }

    return result;
}

/**
 *
 * @param date {Date} The date to be formatted into a date string.
 * @returns {string} The formatted date string in 'yyyy-mm-dd' format.
 */
const dateToIsoString = (date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${date.getFullYear()}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;
}

/**
 *
 * @param date {Date} The input time
 * @returns {string} Output string of time in the format of 23:00:00
 */
const dateToTimeString = (date) => {
    const hour = date.getHours() >= 10 ? date.getHours().toString() : `0${date.getHours().toString()}`;
    const minute = date.getMinutes() >= 10 ? date.getMinutes().toString() : `0${date.getMinutes().toString()}`;
    return `${hour}:${minute}:00`;
}

/**
 *
 * @param hour {number} The input time's hour.
 * @param minute {number} The input time's minute.
 * @param second {number} The input time's second.
 * @returns {string} Output string of time in the format of 23:00 if "second" is null or 23:00:00 if "second" is not null.
 */
const createTimeString = (hour, minute, second = null) => {
    return `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}${second === null ? '' : `:${second < 10 ? '0' : ''}${second}`}`;
}

/**
 *
 * @param startDate {Date} The earliest possible start date of an instance of the recurring event.
 * @param endDate {Date} The earliest possible end date of an instance of the recurring event.
 * @param repeatEnd {Date} The latest possible end date of an instance of the recurring event.
 * @param repeatDays {Boolean[]} The days of the week the recurring event occur. repeatDays[day] means the event does/does not occur for the day of the week. The day as an index follows the output of JavaScript Date object's 'getDay' method, where 0 is Sunday and 6 is Saturday.
 * @returns {{startDates: Date[], endDates: Date[]}} The start dates and end dates of all the instances of the recurring event.
 */
const getRepeatRanges = (startDate, endDate, repeatEnd, repeatDays) => {
    const endDateRange = dateRange(new Date(endDate), new Date(repeatEnd));
    const startDateRange = dateRangeByLength(new Date(startDate), endDateRange.length);
    const startDates = [];
    const endDates = [];
    for (let i = 0; i < startDateRange.length; ++i) {
        if (repeatDays[startDateRange[i].getUTCDay()]) {
            startDates.push(startDateRange[i]);
            endDates.push(endDateRange[i]);
        }
    }

    return {startDates, endDates};
}

/**
 *
 * @param recurrenceRule {{second: number|null, minute: number|null, hour: number|null, date: number|null, month: number|null, year: number|null, dayOfWeek: number|null}} A cron-style recurrence rule object.
 * @returns {string} A humanly readable string to describe the recurrence rule.
 */
const recurrenceRuleToString = (recurrenceRule) => {
    let parts = [];

    // Hour
    if (recurrenceRule.hour !== null && recurrenceRule.minute !== null) {
        let hour = recurrenceRule.hour;
        let period = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12 || 12; // Convert 0 to 12 for AM
        parts.push(`at ${createTimeString(hour, recurrenceRule.minute, recurrenceRule.second || null)}${period}`);
    }

    // Day of the week
    if (recurrenceRule.dayOfWeek !== null) {
        parts.push(`on ${weekDays[recurrenceRule.dayOfWeek]}`);
    }

    // Date of the month
    if (recurrenceRule.date !== null) {
        parts.push(`on the ${recurrenceRule.date}${getOrdinalSuffix(recurrenceRule.date)}`);
    }

    // If no specific date or day is provided, it runs daily
    if (recurrenceRule.date === null && recurrenceRule.dayOfWeek === null && recurrenceRule.month === null && recurrenceRule.year === null) {
        parts.push('daily');
    }

    return parts.join(' ');
}

/**
 *
 * @param recurrenceRule {{second: number|null, minute: number|null, hour: number|null, date: number|null, month: number|null, year: number|null, dayOfWeek: number|null}} A cron-style recurrence rule object.
 * @returns {{time: string, frequency: 'D'|'W'|'M', frequency_detail: number|null}} An object containing recurring details for user form submission.
 */
const recurrenceRuleToFormData = (recurrenceRule) => {
    if (!recurrenceRule) return {};
    const formData = {
        time: createTimeString(recurrenceRule.hour, recurrenceRule.minute, recurrenceRule.second),
    };

    if (recurrenceRule.date !== null) {
        formData.frequency = 'M';
        formData.frequency_detail = recurrenceRule.date;
    } else if (recurrenceRule.dayOfWeek !== null) {
        formData.frequency = 'W';
        formData.frequency_detail = recurrenceRule.dayOfWeek;
    } else {
        formData.frequency = 'D';
        formData.frequency_detail = null;
    }

    return formData;
}

/**
 *
 * @param frequency {'D'|'W'|'M'} The frequency of the recurrence rule: 'D' for daily, 'W' for weekly, and 'M' for monthly.
 * @param frequencyDetail {number} If the recurrence is weekly, then it's 0 for Sunday, 1 for Monday, etc. If the recurrence is monthly, then 1 means 1st of the month.
 * @param time {string} The time string in ISO format, for example, "23:00:00".
 * @returns {{hour: number, minute: number, second: number, date: number|null, dayOfWeek: number|null}} The cron-style recurrence rule object based on the specified options.
 */
const formDataToRecurrenceRule = (frequency, frequencyDetail, time) => {
    const timeNumbers = time.split(':');
    const hour = parseInt(timeNumbers[0]);
    const minute = parseInt(timeNumbers[1]);
    const second = parseInt(timeNumbers[2]);
    const recurrenceRule = {
        hour,
        minute,
        second,
    }
    if (frequency === 'D') {
        recurrenceRule.dayOfWeek = null;
        recurrenceRule.date = null;
    } else if (frequency === 'W') {
        recurrenceRule.dayOfWeek = frequencyDetail;
        recurrenceRule.date = null;
    } else if (frequency === 'M') {
        recurrenceRule.date = frequencyDetail;
        recurrenceRule.dayOfWeek = null;
    }

    return recurrenceRule;
}

module.exports = {
    weekDays,
    formatDate,
    getTimeString,
    dateFloor,
    dateCeil,
    timeRange,
    getAvailabilityMap,
    getAvailableSlots,
    getAvailableStartTimes,
    getAvailableEndTimes,
    hasConflict,
    dateAdd,
    dateRange,
    dateRangeByLength,
    dateToIsoString,
    dateToTimeString,
    getRepeatRanges,
    recurrenceRuleToString,
    recurrenceRuleToFormData,
    formDataToRecurrenceRule,
};
